import React from "react";

function AppSelector({
  apps,
  onChangeApp
}) {
  return (
    <div>
      <div>
        <span>Select application</span> :
          {apps && apps.length > 0 && (
          <div>
            <select onChange={onChangeApp}>
              {apps.map((app, index) => {
                return <option>{app.displayName}</option>;
              })}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}

export default AppSelector;