import React from 'react';
import './App.css';
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinkSharpIcon from "@material-ui/icons/LinkSharp";
import AppSelector from "./selector"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    margin: theme.spacing(4, 0, 2)
  }
}));

const apps = [
  {
    packageName: "com.schibsted.iberica.custojusto",
    googlePlayAccountNumber: "6867473817275341920",
    displayName: "CustoJusto"
  },
  {
    packageName: "com.mbdiffusion.agriaffaires",
    googlePlayAccountNumber: "7389743052217256213",
    displayName: "MBD AA"
  },
  {
    packageName: "com.mbdiffusion.machineryzone",
    googlePlayAccountNumber: "7389743052217256213",
    displayName: "MBD MZ"
  },
  {
    packageName: "eu.appsconcept.tupik",
    googlePlayAccountNumber: "7693757804238334243",
    displayName: "Tupik"
  }
]


function generate(data) {
  return data.map((value) => {
    return (
      <ListItem key={value.buildNumber}>
        <ListItemText
          primary={`${value.appVersion} (${value.buildNumber})`}
        />
        <a href={value.installLink}>
          <ListItemIcon>
            <LinkSharpIcon />
          </ListItemIcon>
        </a>
      </ListItem>
    )
  }
  );
}

function InteractiveList({
  data
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.title}>
            Google Play Previous Versions
         </Typography>
          <div className={classes.demo}>
            <List>
              {generate(data)}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function App() {
  const [data, setData] = React.useState([]);
  const [selectedApp, setSelectedApp] = React.useState({
    app: apps[0],
    forceFetch: false
  });

  React.useEffect(() => {
    getData(selectedApp.app.packageName, selectedApp.app.googlePlayAccountNumber, selectedApp.forceFetch)
      .then(data => {
        console.log(data)
        setData(data)
      });
  }, [selectedApp])

  const onChangeApp = (e) => {
    let select = e.target;
    setData([])
    setSelectedApp({
      app: apps[select.selectedIndex],
      forceFetch: false
    })
  }

  const onForceFetchClick = () => {
    setData([])
    setSelectedApp({
      app: selectedApp.app,
      forceFetch: true
    })
  }

  return (
    <div className="App">
      <AppSelector apps={apps} onChangeApp={onChangeApp} />
      <br></br>
      <button onClick={onForceFetchClick}>Force list refresh</button>
      <InteractiveList data={data} />
    </div>
  );
}

function getData(packageName, googleAccountNumber, forceFetch) {
  let url = `https://google-play-previous-versions.coimbra.io/api/previous-versions?packageName=${packageName}&account=${googleAccountNumber}`
  if (forceFetch) {
    url = `${url}&forceFetch=true`
  }
  return fetch(url)
    .then(response => response.json())
}

export default App;
